.ant-steps {
  .ant-steps-item {
    padding-bottom: 28px;
    position: relative;

    .stepContent {
      .match-percent {
        position: absolute;
        top: 0;
        right: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        color: #F37E21;

        @media (max-width: 1024px) {
          font-size: 12px;
        }

        &.success {
          color: $color-green;
        }
      }
    }

    .ant-steps-item-container {
      .ant-steps-item-tail::after {
        content: '';
        display: inline-block;
        width: 5px !important;
        height: 100%;
        vertical-align: middle;
        border-left: 2px dashed $color-gray;
        border-spacing: 5px;
        background: transparent;
      }
    }
  }


}

.ant-tag {
  margin-top: 6px;
  @media (max-width: 1024px) {
    font-size: 9px;
  }
}

.switchlabelarea {
  &:focus-visible {
    outline: none !important;
  }
}

