//Switch
.ant-switch {
  &:focus-visible {
    outline: none !important;
    outline-offset: unset !important;
    transition: outline-offset 0s, outline 0s;
  }

  &.ant-switch-checked {
    background: linear-gradient(107.3deg, #EE3824 13.39%, #F37A20 77.64%);

    &:hover {
      background: linear-gradient(107.3deg, #EE3824 13.39%, #F37A20 77.64%) !important;
    }
  }


  .ant-switch-checked:hover:not(.ant-switch-disabled) {

  }
}

//Humburger
.hamburger-icon {
  width: 20px;
  height: 16px;
  position: relative;
  cursor: pointer;
  margin-left: 20px;
  z-index: 555;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }

  span {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #000;
    border-radius: 3px;
    transition: transform 0.2s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }

  &.active {
    span {
      &:nth-child(1) {
        transform: translateY(509%) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-180%) rotate(-45deg);
      }
    }
  }
}


.sidebar-section {
  height: calc(100vh - 125px);
  padding: 20px;
  border-radius: 20px;
  background: white;
  overflow: auto;

  @media (max-width: 1024px) {
    padding: 20px 10px;
  }
  @media (max-width: 767px) {
    height: auto;
  }

  .searchsidebar {
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }


    label {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      padding-right: 11px;
      color: $color-content-black;
      display: block;
      white-space: nowrap;
      @media (max-width: 1024px) {
        margin-bottom: 15px;
      }
    }


    .selectcustom {


      .ant-select-selection-item {
        font-weight: 400;
        font-size: 12px;
        color: $color-gray;
      }

    }


  }

  .suggestedTags {
    span {
      color: $color-gray;
    }

    .ant-tag {
      color: $color-black;
      font-weight: 500;
      transition: none;
    }
  }

  .ant-switch.ant-switch-checked {
    background: linear-gradient(107.3deg, #EE3824 13.39%, #F37A20 77.64%);
  }

  .ant-switch.ant-switch-checked:hover {
    background: linear-gradient(107.3deg, #F37A20 13.39%, #EE3824 77.64%);
  }


  .switchboxarea {

    margin: 20px 0 0;

    label {
      color: $color-black;
      font-size: 14px;
      font-weight: 500;
    }


  }


  .ant-collapse-expand-icon {
    display: none !important;
  }

  .ant-collapse-item {
    border: 0;
    padding: 0
  }

  .ant-collapse-content {
    background: none !important;
    border: 0px !important;
  }

  .ant-collapse.ant-collapse-icon-position-start {
    background: none;
    border: 0;


  }

  .ant-collapse {
    &:focus-visible {
      outline: none !important;
    }
  }


  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px
  }

  .ant-collapse-item {
    padding: 10px;
    transition: none !important;

    &:focus-visible {
      outline: none !important;
    }

    &.ant-collapse-item-active {
      background: $theme-background;
      border: 1px solid transparent;
      border-radius: 10px;

      &:focus-visible {
        outline: none !important;
      }
    }

  }

  label h5 {
    font-size: 14px;
    padding-left: 12px;
    line-height: 20px;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }

  .ant-slider .ant-slider-track {
    background: linear-gradient(107.3deg, #EE3824 13.39%, #F37A20 77.64%);
  }

  .ant-slider .ant-slider-handle::after {
    background-color: #F37E21;
    box-shadow: 0 0 0 2px #F37E21;
  }


}

.no-hover:hover, .no-hover:hover td.ant-table-cell-row-hover {
  background-color: unset !important;
}

.ant-table-wrapper tr.ant-table-expanded-row > th, .ant-table-wrapper tr.ant-table-expanded-row:hover > th, .ant-table-wrapper tr.ant-table-expanded-row > td, :where(.css-dev-only-do-not-override-1vtf12y).ant-table-wrapper tr.ant-table-expanded-row:hover > td {
  background: transparent;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  border: 0;
}

.ant-table-wrapper .ant-table {


  .ant-table-row {
    border: 0.970498px solid #ECECEC;

    &:hover {
      .ant-table-cell {
        background: transparent;
      }
    }
  }


  th.ant-table-cell {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    padding: 10px 3px;
    margin: 0;
    color: $color-gray;
    font-family: 'Urbanist';
    background-color: $theme-background;
    transition: none !important;
    @media (max-width: 1024px) {
      font-size: 10px;
    }

    &:before {
      content: normal !important;
    }
  }

  td.ant-table-cell {
    font-family: 'Urbanist';
    padding: 24px 10px;
    justify-content: space-between;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: $color-gray;
    background: transparent;
    transition: none !important;
    @media (max-width: 1024px) {
      font-size: 10px;
    }

    :hover {
      background: transparent;
      color: $color-gray;
    }

    p {
      color: $color-gray;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin: 6px 0 0;
      text-align: left;
      @media (max-width: 1024px) {
        font-size: 10px;
      }

      strong {
        display: block;
        font-weight: 600;
        color: #282526;
        font-size: 14px;
        width: 110px;
        @media (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }

    .ellipsized-cell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 110px; /* adjust as needed */
    }

  }
}

.ant-steps-item-container {

  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-description .stepContent .match-percent {
    color: #00B85E;
  }

  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-description .stepContent .match-percent {

  }

  .ant-steps.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    border-left: 1px dashed $color-gray;
    width: 2px;
    height: 100%;
    background: none;
  }

  .ant-steps-item-content .ant-steps-item-title {
    color: $color-black !important;
    opacity: 1;
    line-height: inherit !important;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }

  .stepContent {
    @media (max-width: 1024px) {
      font-size: 10px;
    }
  }


  .ant-steps-item-description {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: #898989;
  }
}

//Side bar Form
textarea {
  &.ant-input {
    border-radius: 10px;
  }
}

.ant-input {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  margin-bottom: 10px;
  transition: none !important;

  @media (max-width: 1024px) {
    font-size: 11px;
  }

  &:hover {
    border-color: $color-gray;
  }
}

.ant-table-head {
  td.ant-table-cell {
    background-color: $theme-background !important;
  }
}

.ant-checkbox + span {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: $color-gray;
}

.ant-checkbox {
  &:not(.ant-checkbox-disabled) {
    &:hover {
      .ant-checkbox-inner {
        border-color: $color-gray;
      }
    }
  }

}

.ant-select-single {
  .ant-select-selector {
    width: 100% !important;
    height: 40px !important;
    padding: 4px 15px;
    border-radius: 50px;
    transition: none !important;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
}


.top-drawer-area {
  .ant-switch-inner {
    .ant-switch-inner-checked, .ant-switch-inner-unchecked {
      display: flex;
      vertical-align: middle;
      height: 100%;
      align-items: center;
    }
  }

}

.sidebarmain {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}
