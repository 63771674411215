// Light Color

$theme-background: #FBFBFD;
$color-white: #ffffff;
$color-black: #000000;
$color-content-black: #282526;
$color-gray: #898989;
$color-sidebarBg: #F4F4F4;
$color-borderECECEC: #ECECEC;
$color-green: #00B85E;
$color-paleBlack: #262626;

$color-lightBlack: #151515;
@mixin theme-colors($light-theme: true) {
  @if $light-theme {
    $color-sidebarBg: #000;
  } @else {
    $color-sidebarBg: #F4F4F4;
  }
}

body {
  &.dark {
    @include theme-colors($light-theme: true);
  }
}

body {
  &.light {
    @include theme-colors($light-theme: false);
  }
}


