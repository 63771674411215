.mainAppWrapper {
  display: flex;
  max-width: 1440px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  border-right: 1px solid $color-borderECECEC;
  background: $color-white;
  overflow: hidden;
  @media (max-width: 767px) {
    overflow: scroll;
  }
}

.sidebarmain {
  padding: 30px;
  height: 100vh;
  width: 114px;
  z-index: 9;
  background: $color-sidebarBg;
  text-align: center;
  border-right: 1px solid $color-borderECECEC;
  border-left: 1px solid $color-borderECECEC;
  overflow: hidden;


  @media (max-width: 1024px) {
    width: 84px;
    padding: 30px 10px;
  }

  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    left: -100%;
    transition: 0.5s ease;
    &.active {

      left: 0;
    }
  }

  ul {
    margin-top: 44px;
    text-align: center;
    position: relative;


    li {


      position: relative;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;


      a {
        margin: 0;
        padding: 0;
        display: block;
        line-height: 0;
        transition: all 0.1s ease-out;

        svg {
          path {
            fill: $color-gray;
          }
        }

        &:hover {
          transition: all 0.1s ease-out;

          &::after {
            content: '';
            position: absolute;
            background: linear-gradient(107.3deg, #EE3824 13.39%, #F37A20 77.64%);
            border-radius: 0px 38.8199px 38.8199px 0px;
            top: 0;
            left: -41px;
            width: 200%;
            height: 100%;
            z-index: -1;
            transition: all 0.1s ease-out;
          }

          svg {
            path {
              fill: $color-white;
            }
          }
        }

        &.active {
          transition: all 0.1s ease-out;

          &::after {
            content: '';
            position: absolute;
            background: linear-gradient(107.3deg, #EE3824 13.39%, #F37A20 77.64%);
            border-radius: 0px 38.8199px 38.8199px 0px;
            top: 0;
            left: -41px;
            width: 200%;
            height: 100%;
            transition: all 0.1s ease-out;
            z-index: -1;
          }

          svg {
            path {
              fill: $color-white;
            }
          }
        }

      }
    }

  }
}


/************************************************************************
Top header
 */


.topheaderbar {
  width: 100%;
  height: 85px;
  background: $color-white;
  border-bottom: 1px solid $color-borderECECEC;
  display: flex;
  justify-content: end;
  align-items: center;
  z-index: 2;

}


.top-drawer-area {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-right: 15px;

  .NotificationIcon {
    position: relative;
    margin: 0 10px;

    span.StatusOnline {
      width: 10px;
      height: 10px;
      background: $color-green;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 150px;
    }

  }
}


.dashboard-layout {
  width: 100%;
  height: 100%;
  color: #fff;
  background: $theme-background;
}

.candidateDetail {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .candidateBox {
    display: flex;
    align-items: center;
    margin-right: 32px;

    .para {
      margin-left: 10px;

      h5 {
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        color: $color-gray;
        text-transform: uppercase;
      }

      p {
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        color: #282526;
      }
    }
  }
}

.ant-table-thead {
  tr {
    td {
      &.ant-table-cell {
        background-color: $theme-background !important;
      }
    }
  }

}

//Sorter arrow
:where(.css-dev-only-do-not-override-1vtf12y).ant-table-wrapper .ant-table-column-sorter-up.active, :where(.css-dev-only-do-not-override-1vtf12y).ant-table-wrapper .ant-table-column-sorter-down.active{
  color: #ef5123;
}


//Main Search bar
.mainSearchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  @media (max-width: 767px) {
    margin-top: 20px;
  }

  .searchMatch {
    p {
      color: $color-black;
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }
  }
}

.actionBox {

  .dropdownHolder {
    padding: 10px;
    border-radius: 30px;
    background: $theme-background;
    color: $color-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 107px;
  }
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-1vtf12y).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: $color-gray;
}


.ant-table-wrapper .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  content: normal;
}

.tabularSection { //scroll to rable
  height: calc(100vh - 200px);
  overflow-y: auto;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  border-radius: 0;
}