* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: $theme-background;
  font-style: normal;
  font-size: 16px;
  font-family: 'Urbanist', sans-serif;
  color: $color-black;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

p {
  @media (max-width: 1024px) {
    font-size: 12px;
  }
}

.ant-dropdown-menu-title-content, .ant-select-item-option-content {
  font-size: 12px;
}

::-webkit-scrollbar {
  // width: 5px;
  display: none;
}

::-webkit-scrollbar-track {
  background: #c4c4c4;
}

::-webkit-scrollbar-thumb {
  background: $color-sidebarBg;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-sidebarBg;
}

.dark {
  h1, h2, h3, h4, h5, p, span, label {
    color: white;
  }

  .hamburger-icon span {
    background: $color-gray;
  }

  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: $color-gray;
      }
    }

    .ant-checkbox-inner {
      background: transparent;
      transition: none;
    }
  }

  .sidebar-section {
    .searchsidebar {
      label {
        color: white;
      }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $color-paleBlack;
    border-color: $color-paleBlack;
  }

  .mainAppWrapper {
    background-color: $color-paleBlack;
  }

  .ant-dropdown-menu, .ant-select-dropdown {
    background-color: $color-paleBlack;
    color: $color-gray;
    background: #1e1e1e;
    .ant-select-item-option-content{
      color: white;
    }
  }

  .ant-table-wrapper .ant-table th.ant-table-cell {
    background-color: $color-paleBlack;
    color: $color-gray;
    border: 0;
  }

  .ant-table-column-title {
    color: $color-gray;
  }

  .ant-table-thead tr td.ant-table-cell {
    background-color: $color-paleBlack !important;
  }

  :where(.css-dev-only-do-not-override-1vtf12y).ant-table-wrapper table {
    border-radius: 0;
  }

  .no-hover:hover, .no-hover:hover td.ant-table-cell-row-hover {
    background-color: $color-paleBlack !important;
  }

  .ant-table-wrapper .ant-table .ant-table-row:hover .ant-table-cell {
    background-color: $color-paleBlack;
  }

  .ant-table-cell-row-hover {
    background: $color-lightBlack;
    background-color: $color-lightBlack;
  }

  :where(.css-dev-only-do-not-override-1vtf12y).ant-table-wrapper .ant-table-row-expand-icon::before, :where(.css-dev-only-do-not-override-1vtf12y).ant-table-wrapper .ant-table-row-expand-icon::after {
    background: $color-gray;
  }

  .top-drawer-area .NotificationIcon span.StatusOnline {
    background: $color-green;
  }

  .NotificationIcon {
    svg {
      path {
        fill: white;
      }
    }
  }

  .switchIcon {
    .ant-switch {
      .ant-switch-inner {
        background: $color-gray;
      }
    }

  }

  .ant-tag {
    border-color: transparent;
  }

  .stepContent {
    color: $color-gray;
  }

  .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
    color: white !important;
  }

  :where(.css-dev-only-do-not-override-1vtf12y).ant-table-wrapper .ant-table {
    background: $color-lightBlack;
    background-color: $color-lightBlack;
  }

  .ant-table-wrapper .ant-table td.ant-table-cell p strong {
    color: white;
  }


  .ant-table-wrapper .ant-table td.ant-table-cell {
    background: $color-lightBlack;
    border-color: $color-lightBlack;
    color: white;
    border-bottom: 1px solid $color-gray;
    border-top: 0;
  }

  .actionBox {

    .dropdownHolder {
      background-color: $color-paleBlack;
      color: $color-gray;
      background: #1e1e1e;

      p {
        color: $color-gray;
      }
    }
  }


  .ant-input {
    background-color: $color-paleBlack;
    color: $color-gray;
    background: #1e1e1e;
    border-color: #1E1E1E;
    border-color: #1E1E1E;
    margin-top: 10px;
    transition: none !important;
    @media (max-width: 1024px) {
      font-size: 12px;
    }

    &:hover {
      border-color: $color-gray;
    }

    &::placeholder {
      color: $color-gray;
      font-size: 10px;
    }
  }

  .ant-select {
    &:hover {
      border-color: $color-gray;
    }
  }


  .ant-select .ant-select-selector {
    background-color: $color-paleBlack !important;
    color: $color-gray !important;
    background: #1e1e1e !important;
    border-color: #1E1E1E !important;

  }

  .sidebarmain, .topheaderbar, .sidebar-section, .mainSearchBar {
    background: $color-lightBlack;
    border-color: $color-lightBlack;
    color: white;

    .ant-select-selection-placeholder {
      color: $color-gray;
    }
  }

  .mainSearchBar .searchMatch p {
    color: white;
  }

  .sidebar-section .ant-collapse-item.ant-collapse-item-active {
    background: $color-paleBlack;
  }

  .ant-switch {
    background: $color-paleBlack;
  }

  .dashboard-layout {
    background: $color-paleBlack;
  }

  //.sidebar-section .ant-switch {
  //  background: linear-gradient(107.3deg, #EE3824 13.39%, #F37A20 77.64%) !important;
  //}

  .darkPara {
    color: $color-gray !important;
  }

  .sidebar-section {
    .suggestedTags {
      .ant-tag {
        color: $color-white;
      }
    }
  }

  .ant-table-wrapper .ant-table .ant-table-row {
    border-color: $color-lightBlack;
    border: 1px solid $color-lightBlack;
  }

}